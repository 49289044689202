import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=0cc0e73c&scoped=true&"
import script from "./CustomerList.vue?vue&type=script&lang=js&"
export * from "./CustomerList.vue?vue&type=script&lang=js&"
import style0 from "./CustomerList.vue?vue&type=style&index=0&id=0cc0e73c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc0e73c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VCard,VContainer,VDataTable,VEditDialog,VFlex,VIcon,VLayout,VTextarea,VTooltip})
