import shop from '../../api/orderTransaction';
import { sendSuccessNotice, sendErrorNotice, refreshResource, playSound } from '@/commons/utils';
import { request_list, request_detail, update_detail, create_detail, remove_detail, import_list, request_header, export_list, print_transaction_v2 } from '@/commons/hpod';
import configs from '@/configs';
import _ from "lodash";
import Vue from 'vue';
var FileSaver = require('file-saver');
const endpoint = 'order_transactions'

// initial state
const state = {
    loading: false,
    edited: false,
    all: {},
    summary: {},
    orderCodes: [],
    transCodes: [],
    selected: { ID: 0, TransactionCode: "", FeeShip: 0, ProductPrice: 15, RealNumberOfPack: 1 },
    listOfDay: {},
    listImportVN: {},
    productNames: []
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getSummary({ commit }, param) {
        // commit("setLoading", { loading: true });
        request_list(`${endpoint}/summary`, data => {
            commit('setSummary', data);
            // commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getListOfDay({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setStoreManagerListOfDay', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getListImportVN({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setListImportVN', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getOrderTransactionList({ commit }, param) {
        shop.getOrderTransactionList(data => {
            commit('setOrderTransactionList', data);
        }, Vue.http, param, this.state.authentication.token);
    },
    putOrderTransaction({ commit, dispatch }, param) {
        commit('setEdited', {edited: false});
        shop.putOrderTransaction(item => {
            if(item.status == 204) {
                sendErrorNotice('Mã bill đã tồn tại.')
            }
            else{
                sendSuccessNotice('Đã thêm mới mã bill.')
                shop.getOrderTransactionList(data => {
                    commit('setOrderTransactionList', data);
                    if(param.PackageType == 1) {
                        dispatch("deposit/getDetail", param.OrderID, {root:true});
                    }
                    else {
                        dispatch("main_order/getOrderDetail", param.OrderID, {root:true});
                    }
                }, Vue.http, { order_id: param.OrderID, package_type: param.PackageType }, this.state.authentication.token);
            }
        }, Vue.http, param, this.state.authentication.token);
    },
    deleteOrderTransaction({ commit, dispatch }, param) {
        const list_param = param.param;
        delete param.param;
        commit('setEdited', {edited: false});
        shop.deleteOrderTransaction(data => {
            commit('deleteOrderTransactionList', {type: param.type, idx: param.idx});
            sendSuccessNotice('Đã xóa mã bill.')
            if(list_param) {
                if(param.type == "transaction") {
                    dispatch('getList', list_param);
                }
                else {
                    dispatch('ordercn/getList', list_param, {root: true});
                }
                dispatch("main_order/getOrderDetail", param.orderId, {root:true});
            }
        }, Vue.http, param, this.state.authentication.token);
    },
    postOrderTransaction({ commit, dispatch }, param) {
        commit('setEdited', {edited: false});
        let packageId = param.PackageId;
        delete param.PackageId;
        let filterTransaction = param.FilterTransaction;
        delete param.FilterTransaction;
        shop.postOrderTransaction(data => {
            commit('setEdited', {edited: true});
            if(data.status == 204) {
                sendErrorNotice('Mã bill đã tồn tại.')
                shop.getOrderTransactionList(data => {
                    commit('setOrderTransactionList', data);
                    if(param.PackageType == 1) {
                        dispatch("deposit/getDetail", param.OrderID, {root:true});
                    }
                    else if (param.OrderID) {
                        dispatch("main_order/getOrderDetail", param.OrderID, {root:true});
                    }
                }, Vue.http, { order_id: param.OrderID, package_type: param.PackageType }, this.state.authentication.token);
            }
            else {
                sendSuccessNotice('Cập nhật dữ liệu thành công.')
                if(param.PackageType == 1) {
                    dispatch("deposit/getDetail", param.OrderID, {root:true});
                }
                else {
                    if(packageId){
                        dispatch("bigPackageVn/getDetail", {'packageId': packageId, 'trancode': filterTransaction ? filterTransaction : ''}, {root:true});
                    }
                    else if (param.OrderID) {
                        dispatch("main_order/getOrderDetail", param.OrderID, {root:true});
                    }
                }
                refreshResource();
            }
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ dispatch, commit }, {data, id, param, order_id, is_print, ignore_commit}) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if(id > 0) {
                    data.ModifiedBy = this.state.authentication.user.username;
                    commit("setLoading", { loading: true });
                    update_detail(endpoint, res => {
                        if (res.data.status === 'fail') {
                            playSound('/vtb-error.mp3');
                            sendErrorNotice('Không thể cập nhật mã bill.')
                        }
                        else {
                            resolve(data.data);
                            sendSuccessNotice('Cập nhật mã bill thành công.')
                            playSound('/vtb-success.mp3')
                            if (data.RequestAction == 'DEL') {
                                dispatch('getList', param);
                                commit("removeItemFromListOfDay", id);
                            }                    
                            if (data.TransactionView == 'order_detail' || data.TransactionView == 'store_tq') {
                                if(data.PackageType == 1) {
                                    dispatch("deposit/getDetail", data.OrderID || order_id, {root:true});
                                    dispatch("changedHistories/getList", {'orderId': data.OrderID  || order_id, 'orderType': configs.ORDER_TYPE.deposit}, {root:true});
                                }
                                else {
                                    dispatch("main_order/getOrderDetail", data.OrderID  || order_id, {root:true});
                                }
                            }
                            if (data.TransactionView == 'store_cn_popup') {
                                if(!ignore_commit) {
                                    commit("setDetail", {data: { ID: 0, TransactionCode: "", FeeShip: 0, ProductPrice: 15, ProductName: data.ProductName, ProductNumber: 1, RealNumberOfPack: 1 }});
                                }
                                dispatch('getListOfDay', param);
                            }
                            if(is_print && res.data.FakePrintDate) {
                                const frame_printing = print_transaction_v2({...res.data}, this.state.authentication.user.username, true)
                                var doc = document.getElementById('print_frame').contentWindow.document;
                                doc.open();
                                doc.write(frame_printing);
                                doc.close();
                                setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
                            }
                            refreshResource();
                        }
                        commit("setLoading", { loading: false });
                    }, Vue.http, data, id, this.state.authentication.token);
                }
                else {
                    data.CreatedBy = this.state.authentication.user.username;
                    delete data.ID;
                    commit("setLoading", { loading: true });
                    create_detail(endpoint, res => {
                        if(res.status == 204) {
                            playSound('/vtb-error.mp3');
                            sendErrorNotice(res.reason)
                            commit("setDetail", res);
                        }
                        else if (res.data.status === 'fail') {
                            playSound('/vtb-error.mp3');
                            sendErrorNotice('Không thể thêm mới mã bill.')
                        }
                        else {
                            resolve(data.data);
                            sendSuccessNotice(data.Action == 'StoreExport' ? 'Xuất kho mã bill thành công.' : 'Thêm mới mã bill thành công.');
                            playSound('/vtb-success.mp3');
                            if (data.TransactionView == 'store_cn_popup') {
                                if(!ignore_commit) {
                                    commit("setDetail", {data: { ID: 0, TransactionCode: "", FeeShip: 0, ProductPrice: 15, ProductName: data.ProductName, ProductNumber: 1, RealNumberOfPack: 1 }});
                                }
                                dispatch('getListOfDay', param);
                            }
                            if (data.TransactionView == 'order_detail') {
                                dispatch('getList', param);
                                if(data.PackageType == 1) {
                                    dispatch("deposit/getDetail", data.OrderID, {root:true});
                                    dispatch("changedHistories/getList", {'orderId': data.OrderID, 'orderType': configs.ORDER_TYPE.deposit}, {root:true});
                                }
                                else {
                                    dispatch("main_order/getOrderDetail", data.OrderID, {root:true});
                                }
                            }
                            refreshResource();
                            if(is_print && res.data.FakePrintDate) {
                                const frame_printing = print_transaction_v2({...res.data}, this.state.authentication.user.username, true)
                                var doc = document.getElementById('print_frame').contentWindow.document;
                                doc.open();
                                doc.write(frame_printing);
                                doc.close();
                                setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
                            }
                        }
                        commit("setLoading", { loading: false });
                    }, Vue.http, data, this.state.authentication.token);
                }
            }, 100)
        })
    },
    updateMultiple({ commit }, param) {
        commit("setLoading", { loading: true });
        shop.updateMultiple(res => {
            commit("setLoading", { loading: false });
            if(res.data.status === 'fail'){
                sendErrorNotice('Không thể cập nhật mã bill.')
            }
            else {
                sendSuccessNotice('Cập nhật mã bill thành công.')
            }
        }, Vue.http, this.state.authentication.token, param);
    },
    removeDetail({ commit, dispatch }, {id, param, order_id}) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa mã bill.')
            }
            else {
                sendSuccessNotice('Đã xóa mã bill thành công.')
                commit("removeItemFromListOfDay", id);
                if (param) {
                    dispatch('getList', param);
                }
                if (order_id) {
                    if(data.PackageType == 1) {
                        dispatch("deposit/getDetail", order_id, {root:true});
                        dispatch("changedHistories/getList", {'orderId': order_id, 'orderType': configs.ORDER_TYPE.deposit}, {root:true});
                    }
                    else {
                        dispatch("main_order/getOrderDetail", order_id, {root:true});
                    }
                }
                refreshResource();
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    duplicateDetail({ commit, dispatch }, {data}) {
        commit("setLoading", { loading: true });
        create_detail('order_transactions/duplicate', res => {
            refreshResource();
            commit("setLoading", { loading: false });
        }, Vue.http, data, this.state.authentication.token);
    },
    import({ commit }, data) {
        commit("setLoading", { loading: true });
        const header = {
            headers: request_header(this.state.authentication.token)
        };
        Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/import/`, data, header).then(function (res) {
            refreshResource();
            sendSuccessNotice('Import dữ liệu thành công.')
        });
    },
    export({ commit }, param) {
        commit("setLoading", { loading: true });
        export_list(endpoint, res => {
            commit("setLoading", { loading: false });
            var blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
            FileSaver.saveAs(blob, `DanhSachMaVanDon_${new Date().getTime()}.xlsx`);
        }, Vue.http, param, this.state.authentication.token, 'export');
    },
    getProductNames({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('order_transactions/product_names', data => {
            commit('setProductNames', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    upload_import_cn_store({ commit }, formData) {
        commit("setLoading", { loading: true });
        import_list(endpoint, res => {
            commit("setLoading", { loading: false });
            if(res.status == 202) {
                sendErrorNotice(res.data.reason)                    
            }
            else {
                sendSuccessNotice('Import dữ liệu thành công.')
                refreshResource();
            }
            
        }, Vue.http, formData, this.state.authentication.token, 'upload/import_cn_store');
    },
    upload_export_cn_store({ commit }, formData) {
        commit("setLoading", { loading: true });
        import_list(endpoint, res => {
            commit("setLoading", { loading: false });
            if(res.status == 202) {
                sendErrorNotice(res.data.reason)                    
            }
            else {
                sendSuccessNotice('Import dữ liệu thành công.')
                refreshResource();
            }
            
        }, Vue.http, formData, this.state.authentication.token, 'upload/export_cn_store');
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setSummary(state, data) {
        state.summary = data;
    },
    removeFromList(state, trancode) {
        state.all.data.splice(state.all.data.indexOf(trancode), 1)
        state.all.total = state.all.data.length
    },
    setOrderTransactionList(state, res) {
        state.orderCodes = res.data.order_codes;
        state.transCodes = res.data.order_transactions;
    },
    updateOrderTransactionList(state, {type, res}) {
        if(type === "order_code"){
            state.orderCodes.push(res.data);
        }
        else {
            state.transCodes.push(res.data);
        }
        state.edited = true;
    },
    deleteOrderTransactionList(state, {type, idx}) {
        if(type === "order_code"){
            state.orderCodes.splice(idx, 1);
        }
        else {
            state.transCodes.splice(idx, 1);
        }
        state.edited = true;
    },
    setEdited(state, { edited }) {
        state.edited = edited;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    removeItemFromListOfDay(state, id){
        _.remove(state.listOfDay.data, {
            'ID': id
        });
        state.listOfDay.total -=1;
    },
    setStoreManagerListOfDay(state, data) {
        state.listOfDay = data;
    },
    setListImportVN(state, data) {
        state.listImportVN = data;
    },
    setProductNames(state, data) {
        state.productNames = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
